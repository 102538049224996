export { ProfilePhotoComponent } from './lib/profile-photo.component';
export { HumanSizePipe } from './lib/human-size.pipe';
export { CountAgdirFileSizePipe } from './lib/pipes/count-agdir-file-size.pipe';
export { CountAgdirFileItemsPipe } from './lib/pipes/count-agdir-file-items.pipe';
export { STORAGE_SIGNED_URL, FileService } from './lib/file.service';
export { FileListComponent } from './lib/file-list.component';
export { FileUploadComponent } from './lib/file-upload.component';
export { CameraUploadComponent } from './lib/camera-upload/camera-upload.component';
export { AgdirFileWithProgress } from './lib/agdir-file-with-progress';
export { FILE_MANAGER_PREFIX } from './lib/file-manager-prefix';
export { AgdirFile } from '@agdir/domain';
export { AgdirFileStatus } from './lib/agdir-file-status';
export { mimeTypeDetectByExtension } from './lib/mime-detect';

export const DIRECTORY_TYPE = 'text/directory'; // Its kind of deprecated, but whatever, we need it for internal use.
export const AGDIR_JOURNAL_FOLDER = 'AGDIR-JOURNAL'; // Its kind of deprecated, but whatever, we need it for internal use.

export const makeJournalUploadFolderPath = (journalName: string): string =>
	`${AGDIR_JOURNAL_FOLDER}/${new Date().getFullYear()}/${AGDIR_JOURNAL_FOLDER}-${journalName.toLocaleUpperCase()}/`;
