import { AgdirFile } from '@agdir/fillagring';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export class AgdirFileWithProgress implements AgdirFile {
	readonly progress = new BehaviorSubject(0);
	readonly done = new ReplaySubject();

	lastModified: number;
	name: string;
	path: string;
	size: number;
	type: string;
	uploadedAt: string | null = null;

	constructor(file: File, folderName: string = '', customName?: string) {
		this.lastModified = file.lastModified;
		this.size = file.size;
		this.type = file.type;
		const fileName = customName || new Date().getTime() + '-' + file.name;
		this.path = folderName.endsWith('/') ? `${folderName}${fileName}` : `${folderName}/${fileName}`;
		this.name = fileName;
	}

	toObject(): AgdirFile {
		return {
			size: this.size,
			type: this.type,
			path: this.path,
			name: this.name,
			lastModified: this.lastModified,
			uploadedAt: this.uploadedAt,
		};
	}
}
